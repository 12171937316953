import { CSSProperties } from "react";

export const Lock = ({
    size = "24",
    color = "currentColor",
    style,
    className,
}: {
    size?: string;
    color?: string;
    style?: CSSProperties;
    className?: string;
}) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={style}
        className={className}
    >
        <path
            d="M17.5385 9.5H16.9231V7.11469C16.9231 4.24656 14.8797 2 11.9772 2C9.06308 2 7.07692 4.29438 7.07692 7.11469V9.5H6.46154C5.104 9.5 4 10.6212 4 12V19.5C4 20.8788 5.104 22 6.46154 22H17.5385C18.896 22 20 20.8788 20 19.5V12C20 10.6212 18.896 9.5 17.5385 9.5ZM8.30769 7.11469C8.30769 4.98406 9.74154 3.25 11.9772 3.25C14.1898 3.25 15.6923 4.9475 15.6923 7.11469V9.5H8.30769V7.11469ZM18.7693 19.5C18.7693 20.1891 18.2169 20.75 17.5385 20.75H6.46156C5.7831 20.75 5.23079 20.1891 5.23079 19.5V12C5.23079 11.3109 5.7831 10.75 6.46156 10.75H17.5385C18.2169 10.75 18.7693 11.3109 18.7693 12V19.5ZM12 13.25C11.3203 13.25 10.7693 13.8097 10.7693 14.5C10.7693 14.9616 11.0185 15.36 11.3846 15.5766V17.625C11.3846 17.97 11.6603 18.25 12 18.25C12.3397 18.25 12.6154 17.97 12.6154 17.625V15.5766C12.9816 15.36 13.2308 14.9612 13.2308 14.5C13.2308 13.8097 12.6797 13.25 12 13.25Z"
            fill={color}
        />
    </svg>
);
