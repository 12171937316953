import { CSSProperties } from "react";

export const Search = ({
    size = "16",
    color = "#3D3D3D",
    style,
}: {
    size?: string;
    color?: string;
    style?: CSSProperties;
}) => (
    <svg width={size} height={size} style={style} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M19.6907 17.5879L15.528 13.5605L15.0701 14.0392L14.0295 12.9986C14.642 12.3003 15.1153 11.4914 15.424 10.6154C15.7348 9.74723 15.8932 8.83184 15.8923 7.90968C15.9084 6.8375 15.6992 5.77388 15.2783 4.78766C14.8668 3.86138 14.2843 3.02108 13.5612 2.31087C12.8498 1.59229 12.0049 1.01961 11.074 0.624978C10.0886 0.200162 9.02489 -0.0125803 7.95194 0.000574919C7.05852 0.00765181 6.17241 0.162369 5.32945 0.45847C4.45 0.77738 3.62508 1.23021 2.88387 1.80094C2.06123 2.46495 1.38754 3.29465 0.906595 4.23611C0.433316 5.13958 0.136631 6.12499 0.0324306 7.13958C-0.0620167 8.17657 0.0503267 9.22198 0.362947 10.2152C0.675568 11.2085 1.18225 12.1298 1.85361 12.9257C2.52233 13.7436 3.35097 14.4165 4.28878 14.903C5.19554 15.3626 6.18032 15.6485 7.19225 15.7459C8.20823 15.831 9.23118 15.7287 10.2102 15.4441C11.2278 15.1442 12.1709 14.6336 12.9784 13.9456L14.0815 15.0487L13.6236 15.5274L17.7863 19.5548C18.0528 19.7675 18.3893 19.8726 18.7295 19.8494C19.0696 19.8262 19.3888 19.6764 19.6239 19.4295C19.8591 19.1826 19.9932 18.8565 19.9997 18.5156C20.0063 18.1747 19.8849 17.8437 19.6595 17.5879H19.6907ZM12.5205 12.4886C11.9231 13.0806 11.2161 13.5507 10.4391 13.8727C9.6441 14.2155 8.78647 14.3891 7.92072 14.3827C7.06165 14.3896 6.21071 14.2159 5.42311 13.8727C4.64612 13.5507 3.9392 13.0806 3.34177 12.4886C2.7467 11.8894 2.27006 11.1833 1.93686 10.4073C1.63253 9.61118 1.49441 8.76119 1.531 7.90968C1.5246 7.03824 1.69451 6.17452 2.03052 5.37044C2.35395 4.59671 2.81993 3.89067 3.40421 3.2891C4.00761 2.70711 4.7132 2.24142 5.48555 1.91541C6.28962 1.5794 7.15335 1.40949 8.02479 1.41589C8.8895 1.40942 9.74644 1.57939 10.5432 1.91541C11.3156 2.24142 12.0212 2.70711 12.6246 3.2891C13.2189 3.88465 13.6893 4.59205 14.0086 5.37044C14.3542 6.17191 14.5279 7.03693 14.5186 7.90968C14.5025 8.77283 14.3076 9.62327 13.9462 10.4073C13.6118 11.1877 13.1274 11.8949 12.5205 12.4886Z"
            fill={color}
        />
    </svg>
);
