"use client";
import { PrimaryLinkButton } from "movestic-ui/tailwind/Buttons";
import Link from "next/link";
import {
    NavigationMenu,
    NavigationMenuIndicator,
    NavigationMenuLink,
    NavigationMenuList,
    navigationMenuTriggerStyle,
    NavigationMenuViewport,
} from "movestic-ui/tailwind/NavigationMenu";
import { Lock } from "movestic-ui/tailwind/icons/Lock";
import { Search } from "movestic-ui/tailwind/icons/Search";
import { getLink } from "movestic-blocks/utils/cms/storyblokHelpers";
import { NavigationitemComponent } from "movestic-blocks/components/__generated__/graphql";
import NavigationMenuContainer from "./NavigationMenuContainer";
import React from "react";

export function DesktopNav({
    navigation,
    showLoginButton,
}: {
    navigation: NavigationitemComponent[];
    showLoginButton?: boolean;
}) {
    const [forceMount, setForceMount] = React.useState(true);
    return (
        <>
            <NavigationMenu className="hidden lg:block" onValueChange={() => setForceMount(false)}>
                <NavigationMenuList>
                    {navigation.map((primaryItem, index) => {
                        const hasChildItems = primaryItem.childItems?.length > 0;
                        const link = primaryItem.navigationLink && getLink(primaryItem.navigationLink);

                        return hasChildItems ? (
                            <NavigationMenuContainer
                                item={primaryItem}
                                key={`secondary_${index}`}
                                forceMount={forceMount}
                            />
                        ) : (
                            <MenuLink
                                linkUrl={link?.linkUrl}
                                linkTitle={String(primaryItem.linkTitle)}
                                key={`secondary_${index}`}
                            />
                        );
                    })}
                    <NavigationMenuIndicator />
                </NavigationMenuList>
                <NavigationMenuViewport />
            </NavigationMenu>
            <div className="hidden gap-6 ml-auto mr-4 lg:flex">
                <Link href="/sok" className="flex items-center gap-2 no-underline">
                    <Search size="22" />
                    <span className="m-0 font-bold font-proximanova">Sök</span>
                </Link>
                {showLoginButton && (
                    <PrimaryLinkButton href="https://app.movestic.se/privat/login">
                        <Lock className="mr-2" />
                        Logga in
                    </PrimaryLinkButton>
                )}
            </div>
        </>
    );
}

function MenuLink({ linkUrl, linkTitle }: { linkUrl: string; linkTitle: string }) {
    return (
        <Link href={linkUrl} legacyBehavior passHref key={`primary_${linkTitle}`}>
            <NavigationMenuLink className={navigationMenuTriggerStyle()}>{linkTitle}</NavigationMenuLink>
        </Link>
    );
}
